body {
  background: rgb(26, 7, 51);
}

div.content {
  padding: 10px;
  width: fit-content;
  margin: 0 auto;
}

img#logo {
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

p#title {
  text-align: center;
  color: rgb(181, 176, 253);
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 25px;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.7);

  /* evitar seleção do texto */
  -webkit-touch-callout: none; /* iPhone OS, Safari */
  -webkit-user-select: none; /* Chrome, Safari 3 */
  -khtml-user-select: none; /* Safari 2 */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

p.subtitle {
  letter-spacing: 3px;
  font-size: 15px;
  margin: 35px 10px 0px 10px;
  width: 275px;
  text-align: center;
  color: rgb(181, 176, 253);
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.7);

  /* evitar seleção do texto */
  -webkit-touch-callout: none; /* iOS, Safari */
  -webkit-user-select: none; /* Chrome, Safari 3 */
  -khtml-user-select: none; /* Safari 2 */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

span#to {
  font-size: 30px;
  margin-top: 10px;
  color: rgb(181, 176, 253);

  /* evitar seleção do texto */
  -webkit-touch-callout: none; /* iPhone OS, Safari */
  -webkit-user-select: none; /* Chrome, Safari 3 */
  -khtml-user-select: none; /* Safari 2 */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

input {
  height: 36px;
  border: 0;
  background: none;
  border-bottom: 1px solid rgb(181, 176, 253);
  margin: 10px;
  margin-top: 4px;
  padding: 10px;
  font-size: 24px;
  text-align: center;
  color: #fff;
  outline: none;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
}

input:hover {
  border-bottom: 1px solid rgb(96, 82, 187);
}

input:focus {
  border-bottom: 1px solid rgb(200, 53, 77);
}

input.base {
  width: 100px;
}

input.valueIn {
  width: 255px;
}

h1.valueOut {
  width: 275px;
  letter-spacing: 2px;
  margin: 30px 10px;
  word-wrap: break-word;
  text-align: center;
  color: #fff;
  font-weight: 400;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.7);
}

button.convert {
  background: linear-gradient(225deg, rgb(72, 51, 98), rgb(200, 53, 77));
  background-size: 125%;
  width: 275px;
  height: 50px;
  border: 0;
  margin: 10px;
  margin-bottom: 15px;
  padding: 10px;
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2px;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  outline: none;
  cursor: pointer;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
}

button.convert:hover {
  background-position: -65px;
}

/* PLACEHOLDER */

::-webkit-input-placeholder {
  color: rgb(96, 82, 187);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(96, 82, 187);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(96, 82, 187);
}

:-ms-input-placeholder {
  color: rgb(96, 82, 187);
}
